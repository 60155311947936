import Template from "./template";

const templates = [
  {
    id: "default",
    label: "Document",
    template: Template
  }
];

export default templates;
